import {
    Button,
    Center,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    chakra,
    useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'

import { Card } from '../components/Card'
import DividerWithText from '../components/DividerWithText'
import { Layout } from '../components/Layout'
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom'

export default function UserInvite() {
    const history = useHistory()
    const { userInvite } = useAuth()
    const toast = useToast()
  
    const [email, setEmail] = useState('2rajendrakumar@gmail.com')
  
    return (
      <Layout>
        <Heading textAlign='center' my={12}>
          Email Verified Page
        </Heading>
        <Card maxW='md' mx='auto' mt={4}>
          <chakra.form
            onSubmit={async e => {
              e.preventDefault()
              // your login logic here
              try {
                await userInvite(email)
                toast({
                  description: `An email is sent to ${email} for Email Verify.`,
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                })
              } catch (error) {
                console.log(error.message)
                toast({
                  description: error.message,
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                })
              }
            }}
          >
            <Stack spacing='6'>
            <FormControl id='email'>
              <FormLabel>Email address</FormLabel>
              <Input
                name='email'
                type='email'
                autoComplete='email'
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
              <Button type='submit' colorScheme='pink' size='lg' fontSize='md'>
                Submit
              </Button>
            </Stack>
          </chakra.form>
        </Card>
      </Layout>
    )
  }
  