import {
  GoogleAuthProvider,
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  doResetPassword,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import React, { createContext, useContext, useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import CookiesNew from 'universal-cookie';
import { auth } from '../utils/init-firebase'

const cookies = new CookiesNew();
//Cookies.set('myCat', 'Pacman', { domain: '.mydomain.com' });
//Cookies.set('name', 'value', { domain: 'localhost' })
//console.log('cookiee valueeeee ',Cookies.get('name'))
const AuthContext = createContext({
  currentUser: null,
  signInWithGoogle: () => Promise,
  login: () => Promise,
  register: () => Promise,
  logout: () => Promise,
  forgotPassword: () => Promise,
  resetPassword: () => Promise,
  userInvite: () => Promise,
  emailVerified: () => Promise
})
const BaseUrl = `https://us-central1-recruitment-tracking-app-dev.cloudfunctions.net/webApi`
// const BaseUrl = `/hiremeAPI`
export const useAuth = () => useContext(AuthContext)

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user ? user : null)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(async () => {
    console.log('The user is', currentUser)
    //let checkAuthUrl = `http://localhost:7000/checkAuth`
    let checkAuthUrl = `${BaseUrl}/checkAuth`
   // https://us-central1-recruitment-tracking-app-dev.cloudfunctions.net/webApi/checkAuth
    if(currentUser === null){
      console.log('check session and verified')
      // for auth check
      
      let session = Cookies.get('__session')
      console.log('session:: ',session)
      //cookies.set("test", "test valueee", {path: "/", domain: ".example.com"}) 

      if(session !== null && session !== "" && session !== undefined){
        try{
          const authResponse = await fetch(checkAuthUrl,{
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': 'http://main.hireme.cloud/',
              'Access-Control-Allow-Credentials': '*',
              "Cookie": Cookies.get('__session')
            },
            body: JSON.stringify({"__session": Cookies.get('__session'), "tenantId":  Cookies.get('tenantId')} ),
          });

          if (!authResponse.ok) {
            console.log('fffff')
            //throw new Error(`Error! status: ${authResponse.status}`);
            return
          }

          const AuthResult = await authResponse.json();
          console.log('AuthResult are:: ',AuthResult)
          localStorage.setItem('customToken',AuthResult.customToken)

          let loginResponse = await signInWithCustomToken(auth, AuthResult.customToken)
          console.log('loginResponse are:: ',loginResponse)
        }catch(error){
          console.log('errrorrr: ',error)
        }
    }else{
      localStorage.removeItem("token")
      localStorage.removeItem("customToken")
      Cookies.remove('__session', {domain: ".hireme.cloud"});
      Cookies.remove('tenantId', {domain: ".hireme.cloud"});
      return signOut(auth)
    }
   }

  }, [currentUser])

  async function login(email, password) {
    
    auth.tenantId = "Rajendra-sjdjjjbRbre-creew";
    // let token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY2MzczNjYwNCwiZXhwIjoxNjYzNzQwMjA0LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay0xbjhlaEByZWNydWl0bWVudC10cmFja2luZy1hcHAtZGV2LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZmlyZWJhc2UtYWRtaW5zZGstMW44ZWhAcmVjcnVpdG1lbnQtdHJhY2tpbmctYXBwLWRldi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6InlEaHVlSmtLZWJoUWg0WGcxdk5RMHVkT0QzODMiLCJ0ZW5hbnRfaWQiOiJSYWplbmRyYS1zamRqampiUmJyZS1jcmVldyIsImNsYWltcyI6eyJwcmVtaXVtQWNjb3VudCI6dHJ1ZX19.XdX3fyKQszDq4vIAVj2c9_JEq-rcNnNoLuqvXh8XBPk4SA6SAKDs6atE7PsZdiGscubcmZh8AXwJRDph_zSj497N8GU2JZEoSmbXb5lyGQgqg_LWdr_6pOJqPNwpL4GX3dZS__8hVBqIm6Ux3eT20VisOyvsxiiwnv1xPD77QMyfMR-TgW1pSFnZYq-7nGlp4KlNC7C4uv9RwYvTS1aEBXOG8I6b3knhGwSTvjl3taj5PYBZJ6cwQtxrvHb7woipiQdHkLuD84tR1geIF3zdCSXLgH-8XCuYncgu5Cmf9WFW3AaXJUIqZq1Ya0-7uuBABLv4p5sAyclxAiP_EKd-tw"
    // console.log('tokennn are::: ',token)
    //return signInWithCustomToken(auth, token)
    // let result =  await signInWithEmailAndPassword(auth, email, password)
    // console.log('result:: ',result)


    // Cloud API
    let url = `${BaseUrl}/sessionLogin`
    let checkAuthUrl = `${BaseUrl}/checkAuth`


    // local API

    // let checkAuthUrl = `http://localhost:5001/recruitment-tracking-app-dev/us-central1/webApi/checkAuth`

    // let url = `https://us-central1-recruitment-tracking-app-dev.cloudfunctions.net/webApi/sessionLogin`

    // As httpOnly cookies are to be used, do not persist any state client side.
   // await app.auth().setPersistence(app.auth.Persistence.LOCAL);

        let userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('userCredential are:: ',userCredential)
        let tenantId = userCredential.user.tenantId
        let idToken = await auth.currentUser.getIdToken()
        console.log('idToken are:: ',idToken)

        // for session create
        const sessionResponse = await fetch(url,{
          method: 'POST',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': 'http://localhost:8080',
            'Access-Control-Allow-Credentials': '*'
          },
          body: JSON.stringify({ idToken, tenantId:tenantId }),
          });
    
        if (!sessionResponse.ok) {
          throw new Error(`Error! status: ${sessionResponse.status}`);
        }
    
        const sessionResult = await sessionResponse.json();
        console.log('sessionResult are:: ',sessionResult)
        Cookies.set('__session', sessionResult.sessionCookie, {domain: ".hireme.cloud"});
        //cookies.set("test", sessionResult.sessionCookie, {path: "/", domain: ".example.com"}) 
        Cookies.set('tenantId', tenantId, {domain: ".hireme.cloud"});

        // for auth check 

        const authResponse = await fetch(checkAuthUrl,{
                method: 'POST',
                credentials: 'include',
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  'Access-Control-Allow-Origin': 'http://localhost:3000',
                  'Access-Control-Allow-Credentials': '*',
                  "Cookie": Cookies.get('__session')
                },
                body: JSON.stringify({"__session": Cookies.get('__session'), "tenantId":  Cookies.get('tenantId')} ),
          });
    
        if (!authResponse.ok) {
          throw new Error(`Error! status: ${authResponse.status}`);
        }
    
        const AuthResult = await authResponse.json();
        console.log('AuthResult are:: ',AuthResult)
        if(AuthResult.status === 'success'){
          localStorage.setItem('customToken',AuthResult.customToken)
          let loginResponse = await await signInWithCustomToken(auth, AuthResult.customToken)
          console.log('loginResponse are:: ',loginResponse)
        }


    // result.user.getIdToken().then(idToken => {
    //   // Session login endpoint is queried and the session cookie is set.
    //   // CSRF protection should be taken into account.
    //   // ...
    //  console.log('idtoken :: ',idToken)
    //  localStorage.setItem('token', idToken)
    //   return fetch("http://localhost:7000/sessionLogin", {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       'Access-Control-Allow-Credentials': true
    //      // "CSRF-Token": Cookies.get("XSRF-TOKEN"),
    //     },
    //     body: JSON.stringify({ idToken }),
    //   });
    // });
  }

  async function register(email, password) {
    let userCredential =  await createUserWithEmailAndPassword(auth, email, password)
    console.log('userCredential are::: ',userCredential)
    let actionCodeSettings = {

      url: "http://localhost:3000/email-verified",

      handleCodeInApp: false

    }
    await sendEmailVerification(userCredential.user, actionCodeSettings);
    return
  }

  function forgotPassword(email) {
    console.log('forgotPassword hereee')
    let actionCodeSettings = {

      url: "http://localhost:3000/reset-password",

      handleCodeInApp: false

    }
    //auth.tenantId = "Rajendra-sjdjjjbRbre-creew";
    //return doResetPassword(email)
  }

  async function resetPassword(oobCode, newPassword) {
    console.log('authhhh:: ',auth)
    let res = await confirmPasswordReset(auth, oobCode, newPassword)
    console.log('resss:: ',res)
    return 
  }

  function userInvite(email) {
    console.log('userInvite hereee:: ',email)
    let actionCodeSettings = {

      url: "http://localhost:3000/email-verified",

      handleCodeInApp: false

    }
    return sendEmailVerification(currentUser,actionCodeSettings)
  }

  function emailVerified (oobCode) {
    console.log('emailVerified hereee:: ')
    return applyActionCode(auth, oobCode)
  }
  function logout() {
    localStorage.removeItem("token")
    localStorage.removeItem("customToken")
    Cookies.remove('__session', {domain: ".hireme.cloud"});
    Cookies.remove('tenantId', {domain: ".hireme.cloud"});
    return signOut(auth)
  }

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider()
    return signInWithPopup(auth, provider)
  }

  const value = {
    currentUser,
    signInWithGoogle,
    login,
    register,
    logout,
    forgotPassword,
    resetPassword,
    userInvite,
    emailVerified
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
