// import { initializeApp } from 'firebase/app'
// import { getAuth } from 'firebase/auth'

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
// }

// const app = initializeApp(firebaseConfig)

// export const auth = getAuth(app)

import { getAuth } from 'firebase/auth'
import { getDatabase } from "@firebase/database";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
const clientCredentials = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
};

function initFirebase() {
    if (typeof window !== undefined) {
        initializeApp(clientCredentials);
    }
}

const app = initializeApp(clientCredentials);
const auth = getAuth(app)
const db = getFirestore(app);

//const realDB = getDatabase(app);

export {initFirebase, auth , db, app };