import { Container, Heading, chakra } from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
import { app, auth, db } from '../utils/init-firebase'

import Cookies from 'js-cookie'
import { Layout } from '../components/Layout'
import axios from "axios";
import { useAuth } from '../contexts/AuthContext'

//import { useEffect } from "react"

export default function Profilepage() {
  const [data, setData] = useState([])
  const { currentUser } = useAuth()
  const token = localStorage.getItem("token")
  console.log('token:: ',token)

  useEffect(()=>{
    //getUsers();
    //getCSRFToken()
},[])
  const getCSRFToken = async () => {
    const response = await fetch('http://localhost:7000/getCSRFToken');
    //fetch.defaults.headers.post['X-CSRF-Token'] = response.data.CSRFToken;
 };

  const handleLogin = async () => {
    console.log('handle login here')
    let data = {
      idToken: token
    }
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "CSRF-Token": Cookies.get("XSRF-TOKEN"),
      },
      body: JSON.stringify(data)

  };

    const response = await fetch('http://localhost:7000/sessionLogin', settings);
    try {
      const cookie = await response.json();
      console.log('Cookie get:', cookie);
    } catch (error) {
      console.log('error:: ',error)
    }
  }
  
  const firestore = getFirestore(app);
const colRef = collection(firestore, `TestApp/WebutersTechnologies-8rml8/HiremeAppp`);
  const getProfile = async () => {
    console.log('handle getProfile here')

    const docRef = doc(db, "TestApp", "WebutersTechnologies-8rml8");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setData(docSnap.data())
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }
  console.log('data are:: ',data)

  return (
    <Layout>
      <Heading>Profile page v9</Heading>
      <input type="button" value="Get Profile" style={{color: '#fff', backgroundColor:'green'}} onClick={getProfile}/>
      <div>
        First Name- {data.first_name}<br/>
        Last Name- {data.last_name}<br/>
        City- {data.city}<br/>
      </div>
      <Container maxW='container.lg' overflowX='auto' py={4}>
        <chakra.pre p={4}>          
          {currentUser && <pre> {JSON.stringify(currentUser, null, 2)}</pre>}
        </chakra.pre>
      </Container>
    </Layout>
  )
}
